import styled from "styled-components";
import fonts from "config/fonts";

import background from "images/background/backgroundWelcome.jpg";

const Content = styled.div`
  flex-direction: row;
  padding: 0 !important;
  background: url(${background}) no-repeat top center;
  background-size:cover;
  height: 100vh;

  font-family: ${fonts.raleway_bold};
  text-align: center;

  .card{
    margin-top: 30vh;
    border-radius: 0px;
    box-shadow: 0px 0px 10px;
  }

  .card-title{
    
    font-size: 1.7rem;
  }

  .logo{
    width: 170px;
  }
 
  .btn1{
    background-color: #20396f;
    border-radius: 5px;
    height: 70px;

    &.active {
      background-color: #405c9a;
    }

    :hover {
      transition: 0.1s ease-in-out;
      background-color: #405c9a;
      text-decoration: none;
    }

  }

  .trabalheConoscoMobile{
    display:none;
  }

  .btn2{
    background-color: #fb7f24;
    border-radius: 5px;
    height: 70px;

    &.active {
      background-color: #ff984d;
    }

    :hover {
      transition: 0.1s ease-in-out;
      background-color: #ff984d;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-bottom: 300px;
    display: flex;
    align-items: center;

    .title {
      margin-top: 32px;
      font-size: 1.4rem;
      font-family: ${fonts.raleway_black};
      color: #ffffff;
      text-align: center;
    }

    .logo{
      padding: 10px;
    }

    .card{
      margin-top: -1px;
    }

    .trabalheConoscoMobile{
      color:#ffffff;
      display:block;
      margin-top: -20px;
      font-family: ${fonts.opensans_regular};
    }

    .card-title{
      font-size: 1.2rem;
    }
  }
`;

export default Content;
