import styled from "styled-components";
import fonts from "config/fonts";

const Content = styled.div`
img{
    width: 100%;
    height: auto;
}

.card{
    width: 20rem;
    height: 100%;
    border-color: #18be57;
}
.card-body{
    color: #ff7313;
    text-align: center;
}

.card-img-top{
    width: 100%;
    height: auto;
}

.card-title{
    height:65px;
    font-size: 1.3rem;
    font-family: ${fonts.opensans_bold};
}

.card-text{
    height:160px;
    font-family: ${fonts.opensans_light};
}

.btn{
    background-color: #18be57;
    border: none;
    border-radius: 0;
    font-family: ${fonts.opensans_bold};
    

    &.active {
      background-color: #62d18c;
    }

    :hover {
      transition: 0.1s ease-in-out;
      background-color: #62d18c;
      text-decoration: none;
    }
}
@media only screen and (max-width: 600px) {
    .card-img-top{
        width: 100%;
    }
    img{
        width: 100%;
        // height: auto;
    }
    .card{
        width: 100%;
        height: 100%;
    }
    .card-text{
        height:100%;
        display: contents;
    }
    .btn{
        
    }
}

`;

export default Content;