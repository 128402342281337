import React from "react";
import Content from "./style";

import { Button } from "react-bootstrap";

import logo_ls from "images/logo/logo-ls.png";
import logo_jacto from "images/logo/logo-jacto.png";

function Welcome() {

    return (
        <Content className="col-12 row">
            <div className="card col-8 col-md-3 offset-2">
                <div className="card-body">
                    <h5 className="card-title">Seja bem vindo a</h5>
                    <h5 className="card-title">Terrazul Tratores</h5>
                    <p className="card-text">O que deseja conhecer primeiro?</p>
                    <a href="/site/ls" className=" btn1 shadow mb-4 d-flex align-items-center justify-content-center">
                        <img className="logo" src={logo_ls} alt="LS Tractor"/>
                    </a>
                    <a href="/site/jacto" className="btn2 shadow mb-4 d-flex align-items-center justify-content-center">
                        <img className="logo" src={logo_jacto} alt="Jacto"/>
                    </a>
                </div>
            </div>
            <div className="trabalheConoscoMobile col-12">
                <a className="trabalheConoscoMobile" href="/terrazultratores/trabalhe-conosco" id="btn-trab">Trabalhe Conosco</a>
            </div>

        </Content>
    );
}

export default Welcome;
