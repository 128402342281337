import React from 'react'

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// import isAuthenticated from './auth'

// Pages
import Home_Page from './pages/home'
import LS_Page from './pages/ls'
import Jacto_Page from './pages/jacto'
import WorkWithUs from './pages/work_with_us'
import Error_Page_404 from './pages/error/404'

// Validation Routes per User
// const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route
//         {...rest}
//         render={props =>
//             isAuthenticated() ? (
//                 <Component {...props} />
//             ) : (
//                     <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
//                 )
//         }
//     />
// )

// Call Routes and validate what user can see
const Routes = () => (
    <BrowserRouter>
        <Switch>            
            {/* <Route path='/ls' component={LS_Page} />
             <Route path='/jacto' component={Jacto_Page} />
           <Route path='/trabalhe-conosco' component={WorkWithUs} /> */}
            <Route path='/' component={Home_Page} />
        </Switch>
    </BrowserRouter>
)

export default Routes