import styled from "styled-components";
import fonts from "config/fonts";

const Content = styled.div`

  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  .splide{
    display: flex;
    justify-content: center;
  }

  img{
    width: 120px;
  }

  .icone_whatsapp{
    width:35px;
  }

  .btn{
    font-family: ${fonts.raleway_bold};
    background-color:#ff0d29;
    border-color:#ff0d29;
    margin-top:10px;
    width:300px;
  }

  
  .item {
  	color: #ffffff;
  }
  

  @media only screen and (max-width: 600px) {
    margin: 0 !important;
    
    .image_box{
  	width: 90%;
  }

`;

export default Content;
