import React, { useEffect, useState } from "react";
import Content from "./style";
import ScrollableAnchor from "react-scrollable-anchor";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import { Button } from "react-bootstrap";

import Fade from "react-reveal/Fade";

import icone_whatsapp_fundo from "images/icons/icone-whatsapp-fundo.png";
import camso from "images/logo/logo-camso.png";
import yanmar from "images/logo/logo-yanmar.png";
import miac from "images/logo/logo-miac.png";
import kamaq from "images/logo/logo-kamaq.png";
import tatu from "images/logo/logo-tatu.png";

function Agent() {

    const [isMobile, setMobile] = useState(1);
    const [show, setShow] = useState(false);
    
    useEffect(() => {
        setShow(false);
        let type = 1;
        if (window.innerWidth >= 920) {
            type = 2;
        }
        if (window.innerWidth >= 1280) {
            type = 3;
        }
        setMobile(type);
        setShow(true);
    }, [window.innerWidth]);

    return (
        <ScrollableAnchor id="agent">
            <Content>
                <Fade top>
                    <div className="splide col-12 row">
                    <Splide options={{
                            rewind: true,
                            width: "100%",
                            gap: '1.5rem',
                            perPage: 5,
                            pagination: false,
                        }}>
                    {/* {props.videos?.map((item, index) => */}
                        <SplideSlide className="d-flex align-items-center">
                            <img src={camso} style={{width:"90px"}}/>
                        </SplideSlide>
                        <SplideSlide className="d-flex align-items-center">                
                            <img src={yanmar}/>
                        </SplideSlide>
                        <SplideSlide className="d-flex align-items-center">                
                            <img src={miac}/>
                        </SplideSlide>
                        <SplideSlide className="d-flex align-items-center">                
                            <img src={kamaq}/>
                        </SplideSlide>
                        <SplideSlide className="d-flex align-items-center">                
                            <img src={tatu}/>
                        </SplideSlide>
                    {/* )} */}
                        </Splide>
                    </div>
                </Fade>
                <Fade bottom >
                    <div className="fade-bottom d-flex justify-content-center">
                        <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?" className="shadow btn btn-danger mb-4 d-flex align-items-center">
                            <img className="icone_whatsapp mr-1" src={icone_whatsapp_fundo} alt="Whatsapp"/>
                            Fale com nosso representante
                        </a>
                    </div>
                </Fade>
            </Content>
        </ScrollableAnchor>
            
    );
}

export default Agent;
