import styled from "styled-components";
import fonts from "config/fonts";

const Content = styled.div`
  
margin-bottom:25px;

.conteudo{
  display:flex;
  flex-direction: row;
  justify-content: center;  
}

.demarcador{
  height: 40px;
  width: 40px;

  border-bottom-style: solid;
  border-bottom-color: #20396f;
  border-bottom-width: 3px;
  border-right-style: solid;
  border-right-color: #20396f;
  border-right-width: 3px;

  margin: -10px 0 10px -40px;
}

  .pecas{
    width:500px;
    height:auto;
  }

  .icone_whatsapp{
    margin-top: 10px;
    margin-left:10px;
    width:40px;
    height: 40px;
  }

  .title{
    font-family: ${fonts.opensans_bold};
    font-size: 2rem;
    margin-bottom:20px;
    color:#20396f;
  }


  .texto{
    font-family:${fonts.opensans_regular};
    text-size:0.8rem;
    color:#20396f;
  }

  .botao{
    padding-left:0px;
  }

  .btn{
    font-family:${fonts.opensans_bold};
    color: white;
    font-size: 1.2rem;
    text-aling: justify;
    background-color:#fc1347;
    margin-top:10px;
    width:250px;
    border-radius: 0;

    &.active {
      background-color: #ff3a66;
      }

      :hover {
      transition: 0.1s ease-in-out;
      background-color: #ff3a66;
      text-decoration: none;
      }
  }
  
  .icones{
    margin-bottom:20px;
    font-family:${fonts.opensans_bold};

    img{
      width:40px;
      heigth:auto;
    }
  }
 
  

  @media only screen and (max-width: 600px) {
    .conteudo{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    

    .title{
      text-align: center;
    }

    .texto{
      margin-top: 20px;
      text-align: center;
    }

    .left-side{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .right-side{
      height: 350px;
    }

    .botao{
      display: flex;
      flex-direction: row;
      justify-content:center;

      
    }

    .btn{
      font-size: 1rem;
      width: 200px;
    }

    .pecas{
      width: 300px;
      
      margin-bottom: 15px;
    }
  }
`;

export default Content;
