import React from "react";
import Content from "./style";

// import banner from "images/banner/banner-economia-combustivel.jpg";

function Banner() {

    return (
        <Content>
            <div className="text col-12 col-md-3">
                <strong>ECONOMIA DE<br/>COMBUSTÍVEL<br/>NA PULVERIZAÇÃO.</strong>
            </div>
        </Content>
            
    );
}

export default Banner;
