import React, { Fragment, useEffect } from "react";

import {Welcome} from "container";
import { configureAnchors } from "react-scrollable-anchor";

import {FlashMessageCookies, ModalPolitica} from 'components';

const Home_Page = () => {
  // const _translate = useSelector(({translate}) => translate);

  configureAnchors({ offset: -60, scrollDuration: 500 });
  useEffect(() => {}, []);

  return (
    <Fragment>
      <ModalPolitica />
      <FlashMessageCookies/>
      <Welcome />
    </Fragment>
  );
};

export default Home_Page;
