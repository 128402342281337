import React, {useState, useEffect} from "react";
import Content from "./style";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';

import serieR from "images/statics/tratores/serieR.png";
import serieH from "images/statics/tratores/serieH.png";
import seriePlus from "images/statics/tratores/seriePlus.png";
import serieU from "images/statics/tratores/serieU.png";
import serieG from "images/statics/tratores/serieG.png";
import serieMT from "images/statics/tratores/serieMT.png";


import CardLs from "components/cardLs";
import ScrollableAnchor from "react-scrollable-anchor";

import banner from "images/banner/banner-lista-ls.png";
import bannerMobile from "images/banner/BannerJSMobile.png";

function ListaTratores() {

  const [isMobile, setMobile] = useState(false);
  const [img, setImagem] = useState(null);

  useEffect(() => {
      if (window.innerWidth >= 992) {
              setMobile(false);
              setImagem(banner);
      } else {
              setMobile(true);
              setImagem(bannerMobile);
      }
  }, [window.innerWidth]);


  return (
    <ScrollableAnchor id="maquinas">
      <Content className="col-12 d-flex flex-column align-items-center p-0">
       {/* <div className="banner" /> */}
       <img className="banner" src={img} alt=""/>
       {isMobile ? 
        <div className="textos ">
          ZELAMOS PELA ECONOMIA<br/>
          BRASILEIRA DO AGRONEGÓCIO.<br/>
          <p>
          <strong>JUNTOS SOMOS A FORÇA DO CAMPO!</strong>
          </p>
        </div>
        :
        <>
        <div className="demarcador"></div>
        <div className="textos ">
          ZELAMOS PELA<br/>
          ECONOMIA BRASILEIRA<br/>
          DO AGRONEGÓCIO.<br/>
          <p>
          <strong>JUNTOS SOMOS A<br/>
          FORÇA DO CAMPO!</strong>
          </p>
        </div>
        </>
        }
       <div className="title ">Conheça nossos Tratores</div>
       { isMobile ?  
            <Splide className="splide col-12" options={{
                rewind: true,
                width: "100%",
                gap: '2rem',
                perPage: 1,
                pagination: true,
                arrows: false
            }}>
                <SplideSlide className="slide">
                  <CardLs image={serieR} title="Série R" text="Apesar de compactos, os modelos da série R da LS Tractor não economizam na potência e eficiência para médios produtores. Os modelos são ideais para potencializar qualquer produção. Tecnologia, força e robustez. Sobram adjetivos para as máquinas da série R."/>
                </SplideSlide>
                <SplideSlide className="slide">
                  <CardLs image={serieH} title="Série H" text="A principal característica da série é a sua construção robusta, com uma grande capacidade de tração, têm excelente vida útil garantida por um sistema de proteção eletrônica do motor."/>
                </SplideSlide>
                <SplideSlide className="slide">  
                  <CardLs image={seriePlus} title="Série Plus" text="Para os modelos da Série Plus, qualquer terreno é terreno. Além de ideais para o trabalho no campo, são econômicos e possuem baixa emissão de poluentes. Excelente desempenho até em terrenos irregulares."/>
                </SplideSlide>
                <SplideSlide className="slide">  
                  <CardLs image={serieU} title="Série U" text="Uma máquina que combina tecnologia de ponta com a força e a potência de um motor de alto desempenho. Estes são os modelos da Série U da LS Tractor, equipados para potencializar os seus resultados de maneira completa e durável."/>
                </SplideSlide>
                <SplideSlide className="slide">  
                 <CardLs image={serieG} title="Série G" text="São projetados para utilização em médias e grandes propriedades, com um nível tecnológico superior. A TDP Independente é facilmente operada a partir de uma tecla no painel de instrumentos."/>
                </SplideSlide>
                <SplideSlide className="slide">  
                 <CardLs image={serieMT} title="Série MT1.25" text="Desenvolvidos para facilitar o trabalho com rapidez e sem esforço, os modelos da série MT1.25 da LS Tractor proporcionam agilidade na mudança de direção e são equipados com os motores YANMAR, de potência e desempenho confiáveis."/>
                </SplideSlide>
            </Splide>
          
          :
            <div className="maquinas">
              <CardLs image={serieR} title="Série R" text="Apesar de compactos, os modelos da série R da LS Tractor não economizam na potência e eficiência para médios produtores. Os modelos são ideais para potencializar qualquer produção. Tecnologia, força e robustez. Sobram adjetivos para as máquinas da série R."/>
              <CardLs image={serieH} title="Série H" text="A principal característica da série é a sua construção robusta, com uma grande capacidade de tração, têm excelente vida útil garantida por um sistema de proteção eletrônica do motor."/>
              <CardLs image={seriePlus} title="Série Plus" text="Para os modelos da Série Plus, qualquer terreno é terreno. Além de ideais para o trabalho no campo, são econômicos e possuem baixa emissão de poluentes. Excelente desempenho até em terrenos irregulares."/>
              <CardLs image={serieU} title="Série U" text="Uma máquina que combina tecnologia de ponta com a força e a potência de um motor de alto desempenho. Estes são os modelos da Série U da LS Tractor, equipados para potencializar os seus resultados de maneira completa e durável."/>
              <CardLs image={serieG} title="Série G" text="São projetados para utilização em médias e grandes propriedades, com um nível tecnológico superior. A TDP Independente é facilmente operada a partir de uma tecla no painel de instrumentos."/>
              <CardLs image={serieMT} title="Série MT1.25" text="Desenvolvidos para facilitar o trabalho com rapidez e sem esforço, os modelos da série MT1.25 da LS Tractor proporcionam agilidade na mudança de direção e são equipados com os motores YANMAR, de potência e desempenho confiáveis."/>       
            </div>
        }
      </Content>
      </ScrollableAnchor>
  );
}

export default ListaTratores;
