import React, { useEffect, useState } from "react";
import Content from "./style";
import ScrollableAnchor from "react-scrollable-anchor";

import Fade from "react-reveal/Fade";


import pecas from "images/statics/pecas-ls.png";
import icone_whatsapp_fundo from "images/icons/icone-whatsapp-fundo.png";
import { Button } from "react-bootstrap";

function PecasLs(props) {

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
      if (window.innerWidth >= 992) {
              setMobile(false);
      } else {
              setMobile(true);
      }
  }, [window.innerWidth]);
  
  return (
    <ScrollableAnchor id="assistencia_tecnica">
      <Content>
        <div className="col-12 offset-md-1 mt-5" >
        {isMobile ? ""
          :
          <div className="demarcador"></div>
        }
        <div className="title">Peças</div>
        </div>
        <div className="conteudo col-12 row justify-content-center">
          <Fade Left>
            <div className="right-side col-12 col-md-5">
              <img className="pecas" src={pecas} alt="peças jacto"/>
            </div>
          </Fade>
          <Fade Right>
            <div className="left-side col-12 col-md-6 ">
              <div className="texto">Use produtos originais. Só o uso de peças<br/>
              genuínas vai aumentar a vida útil, o<br/>
              desempenho e a produtividade do seu<br/>
              LS. Na Terrazul Tratores você encontra o<br/>
              catálogo completo de peças originais e<br/>
              serviços para seu trator.<br/>
              </div>
              {/* <div className="texto col-12">
                Use produtos originais. Só o uso de peças genuínas vai aumentar a vida útil, o desempenho e a produtividade do seu LS. 
                Na Terrazul Tratores você encontra o catálogo completo de peças originais e serviços para seu trator.
              </div> */}
              <div className="botao row col-12 mt-5">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?" className="shadow btn mb-4 d-flex align-items-center justify-content-center">
                  Solicitar Orçamento
                </a>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?">
                  <img className="icone_whatsapp mr-1" src={icone_whatsapp_fundo} alt="Whatsapp"/>
                </a>
              </div>
            </div>
          </Fade>
        </div>
      </Content>
    </ScrollableAnchor>
  );
}

export default PecasLs;
