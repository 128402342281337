import React, { useEffect, useState } from "react";
import Content from "./style";
import ScrollableAnchor from "react-scrollable-anchor";

import Fade from "react-reveal/Fade";

import juros from "images/icons/icone-juros.png";
import calendario from "images/icons/icone-calendario.png";
import dinheiro from "images/icons/icone-dinheiro.png";
import credito from "images/icons/icone-credito.png";
import imgConsorcio from "images/statics/consorcio-ls.png";
import icone_whatsapp_fundo from "images/icons/icone-whatsapp-fundo.png";
import { Button } from "react-bootstrap";

function ConsorcioLs(props) {

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
      if (window.innerWidth >= 992) {
              setMobile(false);
      } else {
              setMobile(true);
      }
  }, [window.innerWidth]);

  return (
    <ScrollableAnchor id="assistencia_tecnica">
      <Content className="col-12 row justify-content-center">
      <Fade left>
          <div className="image-side col-12 col-md-5">
            {/* <img className="imgConsorcio" src={imgConsorcio} alt="imagem Consorcio"/> */}
          </div>
        </Fade>
        <Fade right>
          <div className="left-side col-11 col-md-5 mt-5">
            { isMobile ? ""
            :
            <div className="demarcador"></div>
            }
            <span className="title">Consórcio LS Tractor</span><br/>
            <span className="texto">Realizar o sonho de melhorar sua propriedade<br/>nunca foi tão fácil
            com o Consórcio LS TRACTOR.
            </span>
            <div className="icones mt-3">
              <img className="mr-2" src={juros} alt="garantia"/>
              Sem Juros
            </div>
            <div className="icones">
              <img className="mr-2" src={calendario} alt="assistencia"/>
              120 Meses para Pagar
            </div>
            <div className="icones">
              <img className="mr-2" src={dinheiro} alt="serviço"/>
              Sem Taxas de Adesão
            </div>
            <div className="icones">
              <img className="mr-2" src={credito} alt="serviço"/>
              Aprovação Rápida de Crédito
            </div>
            <span className="texto">Produtividade garantida para seu agronegócio e garantia<br/>para um futuro rentável a sua família. Tudo sem<br/>comprometer o seu orçamento e seu limite de crédito.
            </span>
            <div className="row">
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?" className="shadow btn mb-4 d-flex align-items-center justify-content-center">
                Solicite Agora
              </a>
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?">
                <img className="icone_whatsapp mr-1" src={icone_whatsapp_fundo} alt="Whatsapp"/>
              </a>
            </div>
          </div>
        </Fade>
      </Content>
    </ScrollableAnchor>
  );
}

export default ConsorcioLs;
