import React, { useState, useEffect } from "react";
import Content from "./style";
import ScrollableAnchor from "react-scrollable-anchor";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';

import Fade from "react-reveal/Fade";

import icone_whatsapp_fundo from "images/icons/icone-whatsapp-fundo.png";

import baldan from 'images/logo/marcas/baldan.png';
import casale from 'images/logo/marcas/casale.png';
import herder from 'images/logo/marcas/herder.png';
import ipacol from 'images/logo/marcas/ipacol.png';
import jacto from 'images/logo/marcas/jacto.png';
import jan from 'images/logo/marcas/jan.png';
import jf from 'images/logo/marcas/jf.png';
import kamaq from 'images/logo/marcas/kamaq.png';
import lavrale from 'images/logo/marcas/lavrale.png';
import marispan from 'images/logo/marcas/marispan.png';
import miac from 'images/logo/marcas/miac.png';
import nogueira from 'images/logo/marcas/nogueira.png';
import piccin from 'images/logo/marcas/piccin.png';
import plantiCenter from 'images/logo/marcas/plantiCenter.png';
import santaizabel from 'images/logo/marcas/santaizabel.png';
import saojose from 'images/logo/marcas/saojose.png';
import tatu from 'images/logo/marcas/tatu.png';
import vicon from 'images/logo/marcas/vicon.png';

import baldanImg from 'images/statics/modalImplementos/baldan.png';
import casaleImg from 'images/statics/modalImplementos/casale.png';
import herderImg from 'images/statics/modalImplementos/Herder.png';
import ipacolImg from 'images/statics/modalImplementos/Ipacol.png';
import jactoImg from 'images/statics/modalImplementos/jacto.png';
import janImg from 'images/statics/modalImplementos/Jan.png';
import jfImg from 'images/statics/modalImplementos/JF.png';
import kamaqImg from 'images/statics/modalImplementos/Kamaq.png';
import lavraleImg from 'images/statics/modalImplementos/Lavrale.png';
import marispanImg from 'images/statics/modalImplementos/Marispan.png';
import miacImg from 'images/statics/modalImplementos/miac.png';
import nogueiraImg from 'images/statics/modalImplementos/Nogueira.png';
import piccinImg from 'images/statics/modalImplementos/piccin.png';
import plantiCenterImg from 'images/statics/modalImplementos/planticenter.png';
import santaizabelImg from 'images/statics/modalImplementos/santaizabel.png';
import saojoseImg from 'images/statics/modalImplementos/saojose.png';
import tatuImg from 'images/statics/modalImplementos/tatu.png';


function ImplementoLs(props) {

  const [foto, setFoto] = useState(plantiCenterImg)

  function mudaImagem(marca){    
    switch(marca){
      case 'baldan':
        setFoto(baldanImg);
        break;
      case 'casale':
        setFoto(casaleImg);
        break;
      case 'herder':
        setFoto(herderImg);
        break;
      case 'ipacol':
        setFoto(ipacolImg);
        break;
      case 'jacto':
        setFoto(jactoImg);
        break;
      case 'jan':
        setFoto(janImg);
        break;
      case 'jf':
        setFoto(jfImg);
        break;
      case 'kamaq':
        setFoto(kamaqImg);
        break;
      case 'lavrale':
        setFoto(lavraleImg);
        break;
      case 'marispan':
        setFoto(marispanImg);
        break;
      case 'miac':
        setFoto(miacImg);
        break;
      case 'nogueira':
        setFoto(nogueiraImg);
        break;
      case 'piccin':
        setFoto(piccinImg);
        break;
      case 'plantiCenter':
        setFoto(plantiCenterImg);
        break;
      case 'santaizabel':
        setFoto(santaizabelImg);
        break;
      case 'saojose':
        setFoto(saojoseImg);
        break;
      case 'tatu':
        setFoto(tatuImg);
        break;
    }
  }

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
      if (window.innerWidth >= 992) {
              setMobile(false);
      } else {
              setMobile(true);
      }
  }, [window.innerWidth]);

  return (
    <ScrollableAnchor id="implementos">
      <Content className="col-12 row justify-content-center">
        <Fade left>
          <div className="left-side col-12 col-md-5 mt-5">
            {isMobile ? 
            <>
            <span className="title1">Implementos</span><br/>
            <span className="texto">Os implementos agrícolas têm revolucionado a forma de fazer o
            agronegócio. A Terrazul Tratores conta com as melhores marcas de
            implementos para qualquer tipo de cultivo. Tecnologia e precisão em
            uma linha completa para te atender da melhor forma e em todas as
            etapas, desde o cultivo até a colheita.
            </span>
            </>
            :
            <>
            <div className="demarcador"></div>
            <span className="title1">Implementos</span><br/>
            <span className="texto">Os implementos agrícolas têm revolucionado a forma de fazer o<br/>
            agronegócio. A Terrazul Tratores conta com as melhores marcas de<br/>
            implementos para qualquer tipo de cultivo. Tecnologia e precisão em<br/>
            uma linha completa para te atender da melhor forma e em todas as<br/>
            etapas, desde o cultivo até a colheita.
            </span>
            </>
            }
            
            <div className="row">
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?" className="shadow btn mb-4 d-flex align-items-center justify-content-center">
                Solicite Agora
              </a>
              <a target="_blank" href="https://api.whatsapp.com/send?phone=5515997831666&text=Gostaria de falar com um consultor?">
                <img className="icone_whatsapp mr-1" src={icone_whatsapp_fundo} alt="Whatsapp"/>
              </a>
              
            </div>
            {isMobile ?
                <Splide className="splide col-12" options={{
                  rewind: true,
                  width: "100%",
                  gap: '1.5rem',
                  perPage: 1,
                  pagination: false
                }}>
                  <SplideSlide className="slide">
                    <a className="marca" onClick={() => { mudaImagem("baldan") }}>
                      <div>
                        <img src={baldan} alt="baldan" />
                      </div>
                    </a>                  
                  </SplideSlide>
                  <SplideSlide className="slide">
                    <a className="marca" onClick={() => { mudaImagem("casale") }}>
                      <img src={casale} alt="casale" />
                    </a>                  
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("herder") }}>
                      <img src={herder} alt="herder" />
                    </a>                  
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("ipacol") }}>
                      <img src={ipacol} alt="ipacol" />
                    </a>                  
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("jacto") }}>
                      <div>
                        <img src={jacto} alt="jacto" />
                      </div>
                    </a>                  
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("jan") }}>
                      <img src={jan} alt="jan" />
                    </a>                 
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("jf") }}>
                      <img src={jf} alt="jf" style={{width:"50px"}} />
                    </a>              
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("kamaq") }}>
                      <img src={kamaq} alt="kamaq" />
                    </a>              
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("lavrale") }}>
                      <img src={lavrale} alt="lavrale" />
                    </a>              
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("marispan") }}>
                      <img src={marispan} alt="marispan" />
                    </a>           
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("lavrale") }}>
                      <img src={lavrale} alt="lavrale" />
                    </a>              
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("miac") }}>
                      <img src={miac} alt="miac" />
                    </a>          
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("nogueira") }}>
                      <img src={nogueira} alt="nogueira" />
                    </a>            
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("piccin") }}>
                      <img src={piccin} alt="piccin" />
                    </a>          
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("plantiCenter") }}>
                      <img src={plantiCenter} alt="plantiCenter" />
                    </a>      
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("santaizabel") }}>
                      <img src={santaizabel} alt="santaizabel" />
                    </a>           
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("saojose") }}>
                      <img src={saojose} alt="saojose" />
                    </a>        
                  </SplideSlide>
                  <SplideSlide className="slide">  
                    <a className="marca" onClick={() => { mudaImagem("tatu") }}>
                      <img src={tatu} alt="tatu" />
                    </a>         
                  </SplideSlide>
              </Splide>
          
          :

            <div className="listaMarcas">
              <a className="marca" onClick={() => { mudaImagem("baldan") }}>
                <div>
                  <img src={baldan} alt="baldan" />
                </div>
              </a>
              <a className="marca" onClick={() => { mudaImagem("casale") }}>
                <img src={casale} alt="casale" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("herder") }}>
                <img src={herder} alt="herder" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("ipacol") }}>
                <img src={ipacol} alt="ipacol" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("jacto") }}>
                <div>
                  <img src={jacto} alt="jacto" />
                </div>
              </a>
              <a className="marca" onClick={() => { mudaImagem("jan") }}>
                <img src={jan} alt="jan" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("jf") }}>
                <img src={jf} alt="jf" style={{width:"45px"}} />
              </a>
              <a className="marca" onClick={() => { mudaImagem("kamaq") }}>
                <img src={kamaq} alt="kamaq" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("lavrale") }}>
                <img src={lavrale} alt="lavrale" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("marispan") }}>
                <img src={marispan} alt="marispan" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("miac") }}>
                <img src={miac} alt="miac" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("nogueira") }}>
                <img src={nogueira} alt="nogueira" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("piccin") }}>
                <img src={piccin} alt="piccin" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("plantiCenter") }}>
                <img src={plantiCenter} alt="plantiCenter" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("santaizabel") }}>
                <img src={santaizabel} alt="santaizabel" style={{width:"75px"}} />
              </a>
              <a className="marca" onClick={() => { mudaImagem("saojose") }}>
                <img src={saojose} alt="saojose" />
              </a>
              <a className="marca" onClick={() => { mudaImagem("tatu") }}>
                <img src={tatu} alt="tatu" />
              </a>
            </div>
            }
          </div>
        </Fade>
        <Fade right>
          <div className="image-side col-12 col-md-6" style={{backgroundImage: `url(${foto})`}}>
            {/* <img className="implemento" src={implemento} alt="imagem implemento"/> */}
          </div>
        </Fade>
      </Content>
    </ScrollableAnchor>
  );
}

export default ImplementoLs;
