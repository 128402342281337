import styled from "styled-components";
import fonts from "config/fonts";


import implemento from "images/statics/implemento.png";

const Content = styled.div`
  flex-direction: row;
  justify-content: center;
  background-color: #eeeeee;

  .image-side{
    background: url(${implemento}) no-repeat center center;
    background-size: contain;
  }
  
  .implemento{
    width:100%;
    height:auto;
  }

  .demarcador{
    height: 40px;
    width: 40px;

    border-bottom-style: solid;
    border-bottom-color: #20396f;
    border-bottom-width: 3px;
    border-right-style: solid;
    border-right-color: #20396f;
    border-right-width: 2px;

    margin: -10px 0 10px -40px;
  }

  .icone_whatsapp{
    margin-top: 10px;
    margin-left:10px;
    width:40px;
    height: 40px;
  }

  .title1{
    font-family: ${fonts.opensans_bold};
    font-size: 2rem;
    margin-right:5px;
    color:#20396f;
  }


  .texto{
    font-family:${fonts.opensans_regular};
    text-size:0.8rem;
    color:#20396f;
  }

  .btn{
    font-family:${fonts.opensans_bold};
    color: white;
    font-size: 1.2rem;
    text-aling: center;
    background-color:#fc1347;
    margin-top:10px;
    width:200px;
    border-radius: 0;

    &.active {
      background-color: #ff3a66;
      }

      :hover {
      transition: 0.1s ease-in-out;
      background-color: #ff3a66;
      text-decoration: none;
      }
  }
  
  .icones{
    margin-bottom:20px;
    font-family:${fonts.opensans_bold};
    color: #20396f;

    img{
      width:40px;
      heigth:auto;
      margin-left: 10px;
    }
  }

  .listaMarcas{
    display: flex;
    flex-wrap: wrap;
    align-items: space-around;
    justify-content: center;
    margin-bottom:30px;
  }

  .marca{
    
    width: 150px;
    height: 70px;

    a{
      display:flex;
      justify-content:center;
      align-items:center;
      
    }
    img{
      width: 100px;
    }
  }

  .slide{
    display: flex;
    align-items: center;
    justify-content: center;

  }
 
  

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding:0px;
    .image-side{
      padding: 110px 0 !important;
    }

    .left-side{
      padding: 0 30px;
    }

    .slide{
      a{

        display:flex;
        align-items:center;
        justify-content:center;
      }
    }
  }
`;

export default Content;
