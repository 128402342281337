import React, { useEffect, useState } from "react";
import Content from "./style";
import ScrollableAnchor from "react-scrollable-anchor";

import Fade from "react-reveal/Fade";
import ModalImplementos from "components/modalImplementos";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';

import baldan from 'images/logo/marcas/baldan.png';
import casale from 'images/logo/marcas/casale.png';
import herder from 'images/logo/marcas/herder.png';
import ipacol from 'images/logo/marcas/ipacol.png';
import jacto from 'images/logo/marcas/jacto.png';
import jan from 'images/logo/marcas/jan.png';
import jf from 'images/logo/marcas/jf.png';
import kamaq from 'images/logo/marcas/kamaq.png';
import lavrale from 'images/logo/marcas/lavrale.png';
import marispan from 'images/logo/marcas/marispan.png';
import miac from 'images/logo/marcas/miac.png';
import nogueira from 'images/logo/marcas/nogueira.png';
import piccin from 'images/logo/marcas/piccin.png';
import plantiCenter from 'images/logo/marcas/plantiCenter.png';
import santaizabel from 'images/logo/marcas/santaizabel.png';
import saojose from 'images/logo/marcas/saojose.png';
import tatu from 'images/logo/marcas/tatu.png';
import vicon from 'images/logo/marcas/vicon.png';

function CarouselTratores(props) {

  const [isMobile, setMobile] = useState(true);
    
    useEffect(() => {

        setMobile(true);

        if (window.innerWidth >= 920) {
            setMobile(false);
        }
        
    }, [window.innerWidth]);

    const [modalShow, setModalShow] = useState(false);

    const [nomeMarca, setNomeMarca] = useState("");

    function abreModal(marca){
      setNomeMarca(marca);
      setModalShow(true);
    }

  return (
    <ScrollableAnchor id="implementos">
      <Fade top>
        <Content className="boxTitulo col-12 row">
          <div className="title col-12 offset-md-1">
          {isMobile ? ""
          :
          <div className="demarcador"></div>
          }
            Nossos parceiros do agro
          </div>
          
          <div className="d-flex justify-content-center align-items-center col-12">

          <Splide className="desktop" options={{
              rewind: true,
              width: "100%",
              gap: '1.5rem',
              perPage: 5,
              pagination: false
          }}>
            {/* {props.videos?.map((item, index) => */}
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("baldan") }}>
                        <img src={baldan}/>
                      </a>
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("casale") }}>
                        <img src={casale}/>
                      </a>
                    </div>  
                  </SplideSlide>
                  <SplideSlide className="imagem">  
                    <div >
                      <a onClick={() => { abreModal("herder") }}>
                        <img src={herder}/>
                      </a>   
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem"> 
                    <div >
                      <a onClick={() => { abreModal("ipacol") }}>
                        <img src={ipacol}/>
                      </a>    
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jacto") }}>
                        <img src={jacto}/>
                      </a>    
                    </div>      
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jan") }}>
                        <img src={jan}/>
                      </a>    
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jf") }}>
                        <img src={jf} style={{width:"90px"}}/>
                      </a>    
                    </div>   
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("kamaq") }}>
                        <img src={kamaq}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("lavrale") }}>
                        <img src={lavrale}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("marispan") }}>
                        <img src={marispan}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("miac") }}>
                        <img src={miac}/>
                      </a>
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("nogueira") }}>
                        <img src={nogueira}/>
                      </a>
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("piccin") }}>
                        <img src={piccin}/>
                      </a>    
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("plantiCenter") }}>
                        <img src={plantiCenter}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("santaizabel") }}>
                        <img src={santaizabel}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("saojose") }}>
                        <img src={saojose}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                    <a onClick={() => { abreModal("tatu") }}>
                      <img src={tatu}/>
                    </a>
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a>
                        <img src={vicon}/>
                      </a>
                      </div>                
                  </SplideSlide>
              {/* )} */}
          </Splide>

          <Splide className="mobile" options={{
              rewind: true,
              width: "100%",
              gap: '1.5rem',
              perPage: 1,
              pagination: false
          }}>
            {/* {props.videos?.map((item, index) => */}
                  <SplideSlide className="imagem" >
                    <div >
                      <a onClick={() => { abreModal("baldan") }}>
                        <img src={baldan}/>
                      </a>
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("casale") }}>
                        <img src={casale}/>
                      </a>
                    </div>  
                  </SplideSlide>
                  <SplideSlide className="imagem">  
                    <div >
                      <a onClick={() => { abreModal("herder") }}>
                        <img src={herder}/>
                      </a>   
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem"> 
                    <div >
                      <a onClick={() => { abreModal("ipacol") }}>
                        <img src={ipacol}/>
                      </a>    
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jacto") }}>
                        <img src={jacto}/>
                      </a>    
                    </div>      
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jan") }}>
                        <img src={jan}/>
                      </a>    
                    </div>
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("jf") }}>
                        <img src={jf}  style={{width:"70px"}}/>
                      </a>    
                    </div>   
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("kamaq") }}>
                        <img src={kamaq}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("lavrale") }}>
                        <img src={lavrale}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("marispan") }}>
                        <img src={marispan}/>
                      </a>    
                    </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("miac") }}>
                        <img src={miac}/>
                      </a>
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("nogueira") }}>
                        <img src={nogueira}/>
                      </a>
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("piccin") }}>
                        <img src={piccin}/>
                      </a>    
                    </div> 
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("plantiCenter") }}>
                        <img src={plantiCenter}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("santaizabel") }}>
                        <img src={santaizabel}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                      <a onClick={() => { abreModal("saojose") }}>
                        <img src={saojose}/>
                      </a>
                      </div>                
                  </SplideSlide>
                  <SplideSlide className="imagem">
                    <div >
                    <a onClick={() => { abreModal("tatu") }}>
                      <img src={tatu}/>
                    </a>
                    </div>                
                  </SplideSlide>
              {/* )} */}
          </Splide>
          </div>

          <ModalImplementos name={nomeMarca}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </Content>
      </Fade>
      
    </ScrollableAnchor>
    
  );
}

export default CarouselTratores;